import { SetupContext } from "@vue/composition-api";

export default function useRole({ root }: Partial<SetupContext>) {
  const getRoleName = (role: string) => {
    switch (role) {
      case "admin":
        return root?.$tc("setup.user.role.admin");
      case "employee":
        return root?.$tc("setup.user.role.employee");
      case "coordinator":
        return root?.$tc("setup.user.role.coordinator");
      case "committee":
        return root?.$tc("setup.user.role.committee");
      case "security":
        return root?.$tc("setup.user.role.security");
      default:
      case "user":
        return root?.$tc("setup.user.role.user");
    }
  };

  const userRole = [
    { value: "admin", text: root?.$tc("setup.user.role.admin") },
    { value: "coordinator", text: root?.$tc("setup.user.role.coordinator") },
    { value: "committee", text: root?.$tc("setup.user.role.committee") },
    { value: "security", text: root?.$tc("setup.user.role.security") },
  ];

  return { getRoleName, userRole };
}
